/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/**
 * Slider Query
 * @class Slider
 * @namespace HyperpayPayments/Query/Hyperpay/Query/HyperpayQuery */
export class HyperpayQuery {
    // createPaymentIntent(options) {
    //     return new Field('createPaymentIntent')
    //         .addArgument('input', 'CreateIntentInput!', options)
    //         .addField('intent_client_secret');
    // }

    // getHyperpayData(paymentMethod) {
    //     console.log("received Payment method: ", paymentMethod);
    //     return new Field('getpaymenturl')
    //     .addArgument('method', 'String', paymentMethod)
    //     .addFieldList(this._getOrderFields());
    // }

    getHyperpayData(orderID) {
        // console.log("received orderId: ", orderID);
        return new Field('getpaymenturl')
        .addArgument('orderId', 'String', orderID)
        .addFieldList(this._getOrderFields());
    }

    _getOrderFields() {
        return [
            'success',
            'message',
            'baseurl',
            'integrity',
            'orderId',
            'formUrl'
        ];
    }
}

export default new HyperpayQuery();
